import { useGetSharesSummary } from "hooks/shares";
import "./CompaniesSummary.scss";

const CompaniesSummary = () => {
  const { data: summary } = useGetSharesSummary();
  return (
    <div className="summary">
      <div className="summary__container">
        <h3>Wartość wykupionych udziałów</h3>
        <p>{summary?.totalValue}</p>
      </div>
      <div className="summary__container">
        <h3>Ilość wykupionych spółek</h3>
        <p>{summary?.totalCount}</p>
      </div>
    </div>
  );
};

export default CompaniesSummary;
