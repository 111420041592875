import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { Toast } from "shared";
import { TOAST_TYPES_ENUM } from "enums";
import { queryClient } from "App";
import { TransactionStoreType } from "types/TransactionsTypes";

export const useStoreTransaction = (
  options?: UseMutationOptions<any, Error, TransactionStoreType>
) => {
  return useMutation({
    ...options,
    mutationKey: ["storeTransaction"],
    mutationFn: async (data) => {
      const formData = new FormData();
      formData.append("shareUuid", data.shareUuid);
      formData.append("sharesCount", `${data.sharesCount}`);
      formData.append("type", data.type);
      data.sharesValue && formData.append("sharesValue", `${data.sharesValue}`);
      data.invoice && formData.append("invoice", data.invoice);

      return axiosInstance
        .post("/transactions", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: "Trwa dodawanie transakcji...",
        type: TOAST_TYPES_ENUM.LOADING,
        id: "store-transaction",
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: "Transakcja dodane pomyślnie.",
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: "store-transaction",
      });
      queryClient.invalidateQueries({ queryKey: ["companies/my"] });
      queryClient.invalidateQueries({ queryKey: ["transactions"] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: "Błąd podczas dodawania udziałów.",
        type: TOAST_TYPES_ENUM.ERROR,
        id: "store-transaction",
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default useStoreTransaction;
