import { SVG_ENUM } from "enums";
import { lazy, Suspense } from "react";

const EyeSVG = lazy(() => import("assets/svg/EyeSVG"));
const CrossedEyeSVG = lazy(() => import("assets/svg/CrossedEyeSVG"));
const CloseSVG = lazy(() => import("assets/svg/CloseSVG"));
const CloseCircleSVG = lazy(() => import("assets/svg/CloseCircleSVG"));
const ChevronUpSVG = lazy(() => import("assets/svg/ChevronUpSVG"));
const ChevronDownSVG = lazy(() => import("assets/svg/ChevronDownSVG"));
const CheckedSVG = lazy(() => import("assets/svg/CheckedSVG"));
const BackChevronSVG = lazy(() => import("assets/svg/BackChevronSVG"));
const AcceptGreenSVG = lazy(() => import("assets/svg/AcceptGreenSVG"));
const LoginBGDesktopSVG = lazy(() => import("assets/svg/LoginBGDesktopSVG"));
const LoginBGMobileSVG = lazy(() => import("assets/svg/LoginBGMobileSVG"));
const LogoSVG = lazy(() => import("assets/svg/LogoSVG"));
const ArrowLeftSVG = lazy(() => import("assets/svg/ArrowLeftSVG"));
const AnnouncementSVG = lazy(() => import("assets/svg/AnnouncementSVG"));
const ArrowReloadSVG = lazy(() => import("assets/svg/ArrowReloadSVG"));
const ArrowUndoSVG = lazy(() => import("assets/svg/ArrowUndoSVG"));
const CalendarSVG = lazy(() => import("assets/svg/CalendarSVG"));
const CloudDownloadSVG = lazy(() => import("assets/svg/CloudDownloadSVG"));
const CreditCardSVG = lazy(() => import("assets/svg/CreditCardSVG"));
const EditSVG = lazy(() => import("assets/svg/EditSVG"));
const FileSVG = lazy(() => import("assets/svg/FileSVG"));
const InfoSVG = lazy(() => import("assets/svg/InfoSVG"));
const LockSVG = lazy(() => import("assets/svg/LockSVG"));
const LinkSVG = lazy(() => import("assets/svg/LinkSVG"));
const ListUnorderedSVG = lazy(() => import("assets/svg/ListUnorderedSVG"));
const LogoutSVG = lazy(() => import("assets/svg/LogoutSVG"));
const PresentationSVG = lazy(() => import("assets/svg/PresentationSVG"));
const RelianceSVG = lazy(() => import("assets/svg/RelianceSVG"));
const SearchSVG = lazy(() => import("assets/svg/SearchSVG"));
const StopSignSVG = lazy(() => import("assets/svg/StopSignSVG"));
const TrashSVG = lazy(() => import("assets/svg/TrashSVG"));
const UserGroupSVG = lazy(() => import("assets/svg/UsersGroupSVG"));
const UserSVG = lazy(() => import("assets/svg/UserSVG"));
const DashboardBGDesktopSVG = lazy(
  () => import("assets/svg/DashboardBGDesktopSVG")
);
const DashboardBGMobileSVG = lazy(
  () => import("assets/svg/DashboardBGMobileSVG")
);
const DotsSVG = lazy(() => import("assets/svg/DotsSVG"));
const PINSVG = lazy(() => import("assets/svg/PinSVG"));
const CheckVerifiedSVG = lazy(() => import("assets/svg/CheckVerifiedSVG"));
const CircleCheckSVG = lazy(() => import("assets/svg/CircleCheckSVG"));
const AwardSVG = lazy(() => import("assets/svg/AwardSVG"));
const CalendarDaysSVG = lazy(() => import("assets/svg/CalendarDaysSVG"));
const CloudUploadSVG = lazy(() => import("assets/svg/CloudUploadSVG"));
const CloseCircleGraySVG = lazy(() => import("assets/svg/CloseCircleGraySVG"));
const CoinsHandSVG = lazy(() => import("assets/svg/CoinsHandSVG"));

type P = {
  type: SVG_ENUM;
  className?: string;
};

const SVG = ({ type, className = "" }: P) => {
  const getSvg = () => {
    switch (type) {
      case SVG_ENUM.LOGO:
        return <LogoSVG className={className} />;
      case SVG_ENUM.EYE:
        return <EyeSVG className={className} />;
      case SVG_ENUM.CROSSED_EYE:
        return <CrossedEyeSVG className={className} />;
      case SVG_ENUM.CLOSE:
        return <CloseSVG className={className} />;
      case SVG_ENUM.CLOSE_CIRCLE:
        return <CloseCircleSVG className={className} />;
      case SVG_ENUM.CHEVRON_UP:
        return <ChevronUpSVG className={className} />;
      case SVG_ENUM.CHEVRON_DOWN:
        return <ChevronDownSVG className={className} />;
      case SVG_ENUM.CHECKED:
        return <CheckedSVG className={className} />;
      case SVG_ENUM.BACK_CHEVRON:
        return <BackChevronSVG className={className} />;
      case SVG_ENUM.ACCEPT_GREEN:
        return <AcceptGreenSVG className={className} />;
      case SVG_ENUM.LOGIN_BG_DESKTOP:
        return <LoginBGDesktopSVG className={className} />;
      case SVG_ENUM.LOGIN_BG_MOBILE:
        return <LoginBGMobileSVG className={className} />;
      case SVG_ENUM.ARROW_LEFT:
        return <ArrowLeftSVG className={className} />;
      case SVG_ENUM.ANNOUCEMENT:
        return <AnnouncementSVG className={className} />;
      case SVG_ENUM.ARROW_RELOAD:
        return <ArrowReloadSVG className={className} />;
      case SVG_ENUM.ARROW_UNDO:
        return <ArrowUndoSVG className={className} />;
      case SVG_ENUM.CALENDAR:
        return <CalendarSVG className={className} />;
      case SVG_ENUM.CLOUD_DOWNLOAD:
        return <CloudDownloadSVG className={className} />;
      case SVG_ENUM.CREDIT_CARD:
        return <CreditCardSVG className={className} />;
      case SVG_ENUM.EDIT:
        return <EditSVG className={className} />;
      case SVG_ENUM.FILE:
        return <FileSVG className={className} />;
      case SVG_ENUM.INFO:
        return <InfoSVG className={className} />;
      case SVG_ENUM.LOCK:
        return <LockSVG className={className} />;
      case SVG_ENUM.LINK:
        return <LinkSVG className={className} />;
      case SVG_ENUM.LIST_UNORDERED:
        return <ListUnorderedSVG className={className} />;
      case SVG_ENUM.LOGOUT:
        return <LogoutSVG className={className} />;
      case SVG_ENUM.PRESENTATION:
        return <PresentationSVG className={className} />;
      case SVG_ENUM.RELIANCE:
        return <RelianceSVG className={className} />;
      case SVG_ENUM.SEARCH:
        return <SearchSVG className={className} />;
      case SVG_ENUM.STOP_SIGN:
        return <StopSignSVG className={className} />;
      case SVG_ENUM.TRASH:
        return <TrashSVG className={className} />;
      case SVG_ENUM.USER_GROUP:
        return <UserGroupSVG className={className} />;
      case SVG_ENUM.USER:
        return <UserSVG className={className} />;
      case SVG_ENUM.DASHBOARD_BG_DESKTOP:
        return <DashboardBGDesktopSVG className={className} />;
      case SVG_ENUM.DASHBOARD_BG_MOBILE:
        return <DashboardBGMobileSVG className={className} />;
      case SVG_ENUM.DOTS:
        return <DotsSVG className={className} />;
      case SVG_ENUM.PIN:
        return <PINSVG className={className} />;
      case SVG_ENUM.CHECK_VERIFIED:
        return <CheckVerifiedSVG className={className} />;
      case SVG_ENUM.CIRCLE_CHECK:
        return <CircleCheckSVG className={className} />;
      case SVG_ENUM.AWARD:
        return <AwardSVG className={className} />;
      case SVG_ENUM.CALENDAR_DAYS:
        return <CalendarDaysSVG className={className} />;
      case SVG_ENUM.CLOUD_UPLOAD:
        return <CloudUploadSVG className={className} />;
      case SVG_ENUM.CLOSE_CIRCLE_GRAY:
        return <CloseCircleGraySVG className={className} />;
      case SVG_ENUM.COINS_HAND:
        return <CoinsHandSVG className={className} />;
      default:
        return null;
    }
  };

  return <Suspense fallback="">{getSvg()}</Suspense>;
};

export default SVG;
