import { VALIDATION_ENUM } from "enums";
import { Field, Formik, Form } from "formik";
import { useGetCountriesCurrency } from "hooks/countries";
import { useStoreShare } from "hooks/shares";
import { useGetClientsOptions } from "hooks/users";
import {
  Button,
  DropFileInput,
  FullscreenModal,
  Input,
  SelectComponent,
} from "shared";
import { CompanyType } from "types/CompanyTypes";
import * as Yup from "yup";

interface AddSharesModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  company?: CompanyType | null;
}

const AddSharesModal: React.FC<AddSharesModalProps> = ({
  isOpen,
  onClose,
  onSuccess,
  company,
}) => {
  const { mutateAsync: storeShare } = useStoreShare();
  const { data: clientOptions } = useGetClientsOptions(isOpen);
  const { data: currencyOptions } = useGetCountriesCurrency();

  const initialValues = {
    userUuid: "",
    companyUuid: company?.uuid ? company.uuid : "",
    sharesCount: 0,
    sharesValue: 0,
    invoice: null,
    currencyUuid: "",
  };

  const onSubmit = async (values: typeof initialValues | any) => {
    //i dont have idea how to type this correctly
    await storeShare(values, {
      onSuccess: () => {
        onSuccess && onSuccess();
        onClose();
      },
    });
    onClose();
  };

  const validation = () =>
    Yup.object().shape({
      userUuid: Yup.string().required(VALIDATION_ENUM.REQUIRED),
      sharesCount: Yup.number().required(VALIDATION_ENUM.REQUIRED),
      sharesValue: Yup.number().required(VALIDATION_ENUM.REQUIRED),
      currencyUuid: Yup.string().required(VALIDATION_ENUM.REQUIRED),
      invoice: Yup.mixed().required(VALIDATION_ENUM.REQUIRED),
    });

  return (
    <FullscreenModal isOpen={isOpen} onClose={onClose}>
      <h2 className="fullscreen-modal__header">
        Dodawanie klienta <i>{company?.name}</i>
      </h2>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => onSubmit(values)}
        validationSchema={validation()}
        enableReinitialize
      >
        {({ errors, values, handleSubmit }) => (
          <Form className="m-width-100">
            <Field
              label="Klient"
              name="userUuid"
              required
              id="userUuid"
              as={SelectComponent}
              options={clientOptions}
              isSearchable
              className={"input__wrapper"}
            />

            <Field
              type="number"
              id="sharesCount"
              name="sharesCount"
              label={"Ilość udziałów"}
              as={Input}
            />

            <Field
              type="number"
              id="sharesValue"
              name="sharesValue"
              label={"Wartość udziałów"}
              as={Input}
            />

            <Field
              label="Waluta"
              name="currencyUuid"
              required
              id="currencyUuid"
              as={SelectComponent}
              options={currencyOptions}
              isSearchable
              className={"input__wrapper"}
            />

            <Field
              label="Umowa"
              name="invoice"
              required
              id="invoice"
              as={DropFileInput}
            />
            <div className="fullscreen-modal__buttons">
              <Button
                label="Anuluj"
                onClick={onClose}
                className="button--stroke button--rounded"
              />
              <Button
                label={"Dodaj"}
                type="submit"
                onClick={() => null}
                className=" button--rounded button--black"
              />
            </div>
          </Form>
        )}
      </Formik>
    </FullscreenModal>
  );
};

export default AddSharesModal;
