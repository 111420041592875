/* eslint-disable react-hooks/exhaustive-deps */
import Table from "rc-table";
import { mobileColumns, desktopColumns } from "./utils";
import { isDesktop } from "utils";
// import { data } from "utils/userBase";
import { CompanyMyType } from "types/CompanyTypes";
import { Tooltip } from "react-tooltip";
import "./TransactionsTable.scss";
import { useGetTransaction } from "hooks/transactions";

interface TransactionsTableType {
  company: CompanyMyType | null;
}

const TransactionsTable = ({ company }: TransactionsTableType) => {
  console.log(company);
  const { data } = useGetTransaction(
    company?.sharesUuid ? company.sharesUuid : ""
  );
  return (
    <div className="companies-table clients-table">
      <Tooltip
        place="top"
        className="tooltip tooltip--noShadow"
        id="company-tooltip"
      />
      {company && (
        <Table
          rowKey="id"
          className="companies-table"
          data={data}
          columns={
            isDesktop() ? desktopColumns(company) : mobileColumns(company)
          }
        />
      )}
    </div>
  );
};

export default TransactionsTable;
