enum SVG_ENUM {
  LOGO = "logo",
  EYE = "eye",
  CROSSED_EYE = "crossed-eye",
  CLOSE = "close",
  CLOSE_CIRCLE = "close-circle",
  CHEVRON_UP = "chevron-up",
  CHEVRON_DOWN = "chevron-down",
  CHECKED = "checked",
  BACK_CHEVRON = "back-chevron",
  ACCEPT_GREEN = "accept-green",
  LOGIN_BG_DESKTOP = "login-bg-desktop",
  LOGIN_BG_MOBILE = "login-bg-mobile",
  ARROW_LEFT = "arrow-left",

  ANNOUCEMENT = "announcement",
  ARROW_RELOAD = "arrow-reload",
  ARROW_UNDO = "arrow-undo",
  CALENDAR = "calendar",
  CLOUD_DOWNLOAD = "cloud-download",
  CREDIT_CARD = "credit-card",
  EDIT = "edit",
  FILE = "file",
  INFO = "info",
  LOCK = "lock",
  LINK = "link",
  LIST_UNORDERED = "list-unordered",
  LOGOUT = "logout",
  PRESENTATION = "presentation",
  RELIANCE = "reliance",
  SEARCH = "search",
  STOP_SIGN = "stop-sign",
  TRASH = "trash",
  USER_GROUP = "user-group",
  USER = "user",
  DASHBOARD_BG_DESKTOP = "dashboard-bg-desktop",
  DASHBOARD_BG_MOBILE = "dashboard-bg-mobile",
  DOTS = "dots",
  PIN = "pin",
  CHECK_VERIFIED = "check-verified",
  CIRCLE_CHECK = "circle-check",
  AWARD = "award",
  CALENDAR_DAYS = "calendar-days",
  CLOUD_UPLOAD = "cloud-upload",
  CLOSE_CIRCLE_GRAY = "close-circle-gray",
  COINS_HAND = "coins-hand",
}

export default SVG_ENUM;
