import { useGetMyCompanies } from "hooks/companies";
import Table from "rc-table";
import { isDesktop } from "utils";
import { desktopColumns, mobileColumns } from "./utilsMyTab";
import CompaniesSummary from "./CompaniesSummary/CompaniesSummary";
import { CompanyMyType } from "types/CompanyTypes";

interface MyCompaniesTabType {
  tab: "my" | "other";
  onRowClick?: (record: any, e: React.MouseEvent<any, MouseEvent>) => void;
  setEditCompany: (company: any) => void;
  handleStatusChange: (company: any, status: any) => void;
  industries: any;
  setShowSellShares: (company: CompanyMyType) => void;
}

const MyCompaniesTab = ({
  tab,
  onRowClick,
  setEditCompany,
  handleStatusChange,
  industries,
  setShowSellShares,
}: MyCompaniesTabType) => {
  const { data: myRelianceData } = useGetMyCompanies("reliance", tab === "my");
  const { data: myOtherData } = useGetMyCompanies("other", tab === "my");
  return (
    <>
      <CompaniesSummary />
      <h2 className="companies-table__title">Spółki wykupione w Reliance</h2>
      <Table
        rowKey="id"
        className="companies-table"
        data={myRelianceData}
        onRow={(record: any) => ({
          onClick: (e) => onRowClick && onRowClick(record, e),
        })}
        columns={
          isDesktop()
            ? desktopColumns({
                setEditCompany,
                handleStatusChange,
              })
            : mobileColumns()
        }
      />
      {myOtherData?.length ? (
        <>
          <h2 className="companies-table__title companies-table__title--other">
            Inne spółki
          </h2>
          <Table
            rowKey="id"
            className="companies-table"
            data={myOtherData}
            onRow={(record: any) => ({
              onClick: (e) => onRowClick && onRowClick(record, e),
            })}
            columns={
              isDesktop()
                ? desktopColumns({
                    setEditCompany,
                    handleStatusChange,
                    isOther: true,
                    setShowSellShares,
                  })
                : mobileColumns()
            }
          />
        </>
      ) : null}
    </>
  );
};

export default MyCompaniesTab;
