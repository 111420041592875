import { UserStatus, ContextMenu, SVG } from "shared";
import { SVG_ENUM } from "enums";
import { UserType } from "types/UsersTypes";
import { CompanyStatusType, CompanyMyType } from "types/CompanyTypes";
import moment from "moment";
import { getCurrencyFromUuid, getIndustryFromUuid } from "utils";

const items = ({
  setEditCompany,
  handleStatusChange,
  setShowSellShares,
}: {
  setEditCompany: (company: CompanyMyType) => void;
  handleStatusChange: (
    company: CompanyMyType,
    status: CompanyStatusType["status"]
  ) => void;
  setShowSellShares?: (company: CompanyMyType) => void;
}) => {
  return (company: CompanyMyType) => {
    const actions = [];

    if (company.origin === "other") {
      actions.push({
        name: "Edytuj",
        action: () => setEditCompany(company),
        svg: SVG_ENUM.EDIT,
      });

      actions.push({
        name: "Usuń",
        action: () => setShowSellShares && setShowSellShares(company),
        svg: SVG_ENUM.TRASH,
      });
    }
    actions.push({
      name: "Zaznacz sprzedaż",
      action: () => setShowSellShares && setShowSellShares(company),
      svg: SVG_ENUM.COINS_HAND,
    });

    return actions;
  };
};

const userItems = () => {
  return (user: UserType) => {
    const actions = [];
    if (user.status !== "inactive") {
      actions.push({
        name: user.status === "suspended" ? "Przywróć" : "Zawieś",
        action: () => console.log(user),
        svg: user.status === "suspended" ? SVG_ENUM.ARROW_UNDO : SVG_ENUM.LOCK,
      });
    }
    return actions;
  };
};

export const mobileColumns = () => [
  {
    width: "calc(50% - 10px)",
    render: (values: any) => (
      <div className="users-table-mobile__name">{`${values.name}`}</div>
    ),
  },
  {
    dataIndex: "status",
    key: "status",
    width: 100,
    render: (value: string) => <UserStatus status={value} />,
  },
  {
    width: 88,
    render: (item: UserType) => (
      <ContextMenu data={item} items={userItems()(item)}>
        <SVG type={SVG_ENUM.DOTS} />
      </ContextMenu>
    ),
  },
];

export const desktopColumns = ({
  setEditCompany,
  handleStatusChange,
  isOther = false,
  setShowSellShares,
}: {
  setEditCompany: (company: CompanyMyType) => void;
  handleStatusChange: (
    company: CompanyMyType,
    status: CompanyStatusType["status"]
  ) => void;
  isOther?: boolean;
  setShowSellShares?: (company: CompanyMyType) => void;
}) => {
  const columns = [
    {
      title: "Nazwa spółki",
      key: "index",
      width: 40,
      render: (value: any, record: CompanyMyType, index: number) => (
        <div
          data-tooltip-id="company-tooltip"
          data-tooltip-content={record.description}
          className="companies-table__info"
        >
          <SVG type={SVG_ENUM.INFO} />
        </div>
      ),
    },
    {
      dataIndex: "name",
      key: "name",
      width: 120,
    },
    {
      title: "Branża",
      width: 100,
      render: (value: any, record: CompanyMyType, index: number) => {
        return `${getIndustryFromUuid(record.industryUuid)}`;
      },
    },
    {
      title: "Wartość udziałów",
      width: 130,
      render: (value: any, record: CompanyMyType, index: number) =>
        `${record.sharesValue} ${getCurrencyFromUuid(record.currencyUuid)}`,
    },
    {
      title: "Waluta",
      width: 60,
      render: (value: any, record: CompanyMyType, index: number) =>
        `${getCurrencyFromUuid(record.currencyUuid)}`,
    },
    {
      title: isOther ? null : "Wycena spółki",
      width: 120,
      render: (value: any, record: CompanyMyType, index: number) =>
        isOther
          ? null
          : `${record.valuation} ${getCurrencyFromUuid(record.currencyUuid)}`,
    },
    {
      title: isOther ? null : "Data publikacji na giełdzie",
      width: 170,
      render: (value: any, record: CompanyMyType, index: number) =>
        record.publicationDate
          ? `${moment(record.publicationDate, "DD/MM/YYYY").format(
              "DD/MM/YYYY"
            )}r.`
          : "",
    },

    {
      title: "",
      dataIndex: "",
      key: "status",
      width: 40,
      render: () => (
        <div className="companies-table__announcement">
          <SVG type={SVG_ENUM.ANNOUCEMENT} />
          <span>0</span>
        </div>
      ),
    },
    {
      width: 20,
      render: (item: CompanyMyType) => (
        <ContextMenu
          data={item}
          items={items({
            setEditCompany,
            handleStatusChange,
            setShowSellShares,
          })(item)}
        >
          <SVG type={SVG_ENUM.DOTS} />
        </ContextMenu>
      ),
    },
  ];

  return columns;
};
